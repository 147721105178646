@import '../../assets/styles/colors';

.menu-wrap {
  display: flex;
  flex-direction: row;
  padding-top: 25px;

  .item {
    margin: 5px;
  }
}
