@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.autoplay-settings {
  width: 300px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &__item {
    margin: 5px 0;
    pointer-events: all;
  }
}

.autoplay-settings::-webkit-scrollbar {
  display: none;
}

.label {
  font-size: 13px;
  font-weight: bold;
  margin: 3px 0;
  color: #fff;
}

.input {
  padding: 1px 2px;
}

.playBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.9;
  }

  img {
    display: block;
    width: 150px;
    height: 39px;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
}
