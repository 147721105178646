@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.settings-menu {
  position: relative;
}

.wrap {
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.settings {
  width: 300px;
  max-width: 100%;
  color: #fff;

  @media screen and (max-width: $breakpoint-mobile-landscape-max) and (orientation: portrait) {
    padding: 0 15px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

.label {
  margin-left: 10px;
}

.btn {
  display: block;
  margin: 10px auto 0;
}
